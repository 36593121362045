.nav-tabs
{
    .nav-link
    {
        font-family: $altFont;
        font-weight: bold;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        opacity: 0.67;

        &.active, &:hover
        {
            opacity: 1;
        }
    }
}
