.promo-block-item
{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .promo-block-shadow
    {
        box-shadow: $baseShadow;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .promo-block-image
    {
        display: block;
        background-color: $colorAlternate;
        padding-bottom: 66.6667%;
        min-height: 15rem;
        position: relative;
        flex-grow: 1;

        @include media-breakpoint-only(xs)
        {
            padding-bottom: 0;
        }

        @include media-breakpoint-down(lg)
        {
            min-height: 10rem;
        }

        > img
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &.promo-block-placeholder
            {
                padding: 2.5rem;
                object-fit: contain;
            }
        }

        .promo-block-content
        {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 2rem;
            text-align: left;
            color: $colorInverse;

            p
            {
                line-height: 1.3333;
            }
        }
    }

    > h3.promo-block-title
    {
        text-align: center;
        text-transform: none;
        letter-spacing: 0;
        font-size: 1.375rem;
        margin: 1rem 0 0;
        font-weight: 600;

        @include media-breakpoint-down(lg)
        {
            font-size: 1.2rem;
        }

        > a
        {
            color: $colorGrey;
            text-decoration: none;
        }
    }
}

a.promo-bar
{
    $bar-height: 3rem;

    display: block;
    background-color: $colorAccent;
    color: $colorInverse;
    text-align: right;
    position: relative;
    text-decoration: none;
    height: $bar-height;
    padding-right: #{$bar-height + .5rem};
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .promo-bar-title
    {
        font-size: .75rem;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $altFont;
        letter-spacing: 1px;
    }

    > .promo-bar-tag
    {
        margin-top: .25em;
        font-size: .675rem;
    }

    &:after
    {
        content: "\f30b";
        position: absolute;
        top: 0;
        right: 0;
        width: $bar-height;
        height: 100%;
        display: block;
        @include line-awesome();
        background-color: #849db0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 1.5rem;
    }
}

#home-promo.card
{
    background-image: linear-gradient(to bottom, transparent, rgba(0,0,0,.67)), url(/site/img/bot-promo-2.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    color: $colorInverse;

    > .card-body
    {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        > div
        {
            margin-top: 1.5rem;
        }
    }

    a
    {
        color: $colorInverse;
        text-decoration: none;

        i
        {
            color: $colorAccent;
        }
    }

    h5
    {
        font-weight: bold;
        width: 60%;
        margin-left: auto;
    }
}

.promo-block-slider
{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 100%;
    overflow: hidden;

    > .promo-block-item
    {
        will-change: transition;
        transition: none;
    }

    &.tick > .promo-block-item
    {
        transform: translateX(-100%);
        transition: .5s linear transform;
    }
    /* IE11 HACK */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
    {
        display: flex;

        > .promo-block-item
        {
            flex: 0 0 100%;
        }
    }
}

.promo-block-single
{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
    gap: 30px;

    .promo-block-item
    {
        .promo-block-shadow
        {
            height: auto;
        }

        .promo-block-image
        {
            flex-grow: 0;
        }
    }
}

.promo-block-item,
#home-promo
{
    h4
    {
        text-transform: none;
        letter-spacing: 0;
        font-size: 1.7em;
        margin-bottom: 0.2rem;

        @include media-breakpoint-down(sm)
        {
            font-size: 1.4rem;
        }
    }

    h5
    {
        text-transform: none;
        letter-spacing: 0;
        font-size: 1.5em;

        @include media-breakpoint-down(sm)
        {
            font-size: 1.3rem;
        }
    }

    p
    {
        font-size: 1.2em;

        @include media-breakpoint-down(sm)
        {
            font-size: 1.1rem;
        }
    }
}

#event-promo
{
    background-color: $colorAlternate;
    color: $colorInverse;
    position: relative;

    h3
    {
        margin-bottom: .667rem;
    }

    .col-md-6:last-child
    {
        margin-top: 3.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    a.promo-bar
    {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
    }

    p.h6,
    .event-promo-link
    {
        text-transform: uppercase;
        font-weight: bold;
        font-size: .8125rem;
        position: relative;
        margin-bottom: 1.5rem;
        letter-spacing: 1px;
    }

    p.h6
    {
        line-height: 2;

        &:after
        {
            content: "";
            border-bottom: 1.5px solid $colorInverse;
            width: 3rem;
            display: block;
            position: absolute;
            bottom: -.25rem;
        }
    }

    h5
    {
        font-size: 1.5rem;
        text-transform: none;
        letter-spacing: 0;
        margin-bottom: .25rem;
    }

    h5 + p.small
    {
        font-weight: 300;
        font-size: .6875rem;
        line-height: 1.25;
    }

    p
    {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.33;
        margin-bottom: .333rem;
    }

    .promo-logos
    {
        display: flex;
        gap: 1rem;
        align-content: center;

        > img,
        > picture
        {
            object-fit: contain;
            object-position: center;
            flex-shrink: 1;
            max-width: calc(50% - .5rem);
        }

        > picture > img
        {
            width: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    .promo-person
    {
        display: flex;
        flex-direction: column;
        gap: 0 1rem;
        text-align: center;
        margin: 0 auto;

        > img,
        > picture
        {
            grid-column: 1;
            grid-row: 1 / -1;
            width: 100%;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;
        }

        > picture > img
        {
            width: 100%;
            height: 100%;
        }

        > h6
        {
            padding-top: 1rem;
            grid-column: 2;
            grid-row: 1;
            font-size: 1rem;
            text-transform: none;
            letter-spacing: 0;
            margin: 0;
        }

        > p
        {
            grid-column: 2;
            grid-row: 2;
            align-self: start;
            text-align: center;
            width: 100%;
            font-size: .85rem;
            line-height: 1.25;
            margin-bottom: 0;
        }
    }

    .event-promo-link
    {
        text-align: right;
        margin: 2.25rem 0 0;
        line-height: 1.25;
        margin-top: auto;

        > a
        {
            color: $colorInverse;
            text-decoration: none;

            > em
            {
                font-style: normal;
                text-decoration: underline;
            }

            .la
            {
                color: $colorAccent;
            }
        }
    }

    @include media-breakpoint-down(sm)
    {
        .col-md-6
        {
            margin-top: 3rem;
        }

        a.promo-bar
        {
            width: 100%;
        }
    }
}
