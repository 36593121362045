.list-alphabet
{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(3.25em, 1fr));
    grid-gap: .25em;
    margin: 0;
    padding: 0;
    letter-spacing: 0;

    > li
    {
        list-style-type: none;
        margin: 0;
        text-align: center;

        label
        {
            display: block;
            margin-bottom: 0;
            cursor: pointer;
        }

        input
        {
            display: none;

            &:checked + .btn-light
            {
                @extend .btn-light.active;
            }
        }

        .btn
        {
            border-radius: 0;
        }
    }
    /* IE11 HACK */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
    {
        display: block;

        > li
        {
            display: inline-block;
            width: 3.25em;
            margin-bottom: .25em;
        }
    }
}
