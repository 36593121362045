.carousel-testimonial
{
    blockquote
    {
        text-align: right;
        font-style: italic;
        font-size: 1.25rem;
        line-height: 1.333;
        padding-left: 6rem;
        position: relative;

        @include media-breakpoint-down(sm)
        {
            padding: 0;
            text-align: center;

            &:before
            {
                display: none;
            }

            p
            {
                font-size: 1rem;
                line-height: 1.5;
            }
        }

        &:before
        {
            content: "\f10d";
            font-style: normal;
            @include line-awesome();
            position: absolute;
            left: 0;
            top: 0;
            font-size: 4rem;
            width: 6rem;
            text-align: left;
            line-height: 0.75;
            opacity: .4;
        }

        > p:last-of-type
        {
            margin-bottom: 0;
        }

        > cite
        {
            font-family: $altFont;
            font-size: .75rem;
            font-style: normal;
            font-weight: bold;
            @include uppercaseText();
        }

        .star-rating
        {
            color: #d4af37;
        }
    }
}
