.card.card-profile
{
    margin-bottom: 2.5rem;

    @include media-breakpoint-down(sm)
    {
        max-width: 20rem;
        margin-left: auto;
        margin-right: auto;
    }

    > .profile-photo
    {
        position: relative;
        flex: 0 0 auto;

        &:after
        {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(black, 0);
            transition: background-color linear 0.25s;
            pointer-events: none;
        }

        img
        {
            width: 100%;
        }
    }

    &:hover
    {
        > .profile-photo:after
        {
            background-color: rgba(black, 0.333);
        }
    }

    > .profile-content
    {
        display: flex;
        flex-grow: 1;

        > .profile-details
        {
            flex: 1 1 auto;
            padding: 1.5rem;
        }

        > .profile-actions
        {
            flex: 0 0 3rem;
            text-align: center;
            background-color: $colorAccent;
            display: flex;
            flex-direction: column;
            justify-content: center;

            > a
            {
                display: block;
                color: $colorInverse;
            }
        }

        .profile-name
        {
            font-weight: bold;
            font-size: 1rem;
            letter-spacing: 0;
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
            color: $colorAccent;

            @include media-breakpoint-down(xs)
            {
                font-size: 1.2rem;
            }

            @include media-breakpoint-up(lg)
            {
                font-size: 1.2rem;
            }
        }

        .profile-title
        {
            font-size: .9rem;
            font-weight: normal;
            color: $colorAlternate;
            line-height: 1;

            @include media-breakpoint-down(xs)
            {
                font-size: 1.1rem;
            }

            @include media-breakpoint-up(lg)
            {
                font-size: 1.1rem;
            }
        }

        .profile-link
        {
            display: block;
            font-size: .75rem;
            font-weight: bold;
            color: $colorAlternate;
            line-height: 1;
            @include uppercaseText();
            text-decoration: none !important;
        }

        .profile-offices
        {
            margin: 0;
            padding: 0;
            list-style-type: none;
            font-size: .6875rem;
            font-weight: bold;
            text-align: left;
            line-height: 1;
            @include uppercaseText();

            &.la-ul
            {
                position: relative;
                padding-left: 1rem;
            }

            li
            {
                margin-bottom: .25rem;
            }

            a
            {
                color: $colorAlternate;
                text-decoration: none !important;
            }
        }

        .profile-offices .fa,
        .profile-link .la
        {
            color: $colorAccent;
        }
    }
}
.page-staff-row > .col-md
{
    @include media-breakpoint-up(md)
    {
        flex: 0 0 33%;
        max-width: 33%;
    }

    @include media-breakpoint-up(xl)
    {
        flex: 0 0 20%;
        max-width: 20%;
    }
}