footer#page-footer
{
    margin: 3rem 0 2rem;
    padding: $sectionPadding 0;
    background-color: $colorBlue;
    color: $colorInverse;

    a
    {
        color: $colorInverse;
    }

    #footer-main
    {
        font-weight: 700;
        font-family: $altFont;

        .nav-pills .nav-link
        {
            border-bottom: $borderSize solid $colorGrey;
            padding: 0.667rem 0;
            @include border-radius(0);
            @include uppercaseText();
            letter-spacing: 1px;
            opacity: 0.6;

            &:after
            {
                content: "\f107";
                float: right;
                @include line-awesome();
            }

            &.active
            {
                background-color: transparent;
                opacity: 1;

                &:after
                {
                    content: "\f105";
                }
            }
        }
    }

    .footer-contact-reviews
    {
        margin: 1.5rem 0 0;
        display: grid;
        grid-template-areas: "a a" "b c";
        grid-gap: .667rem;
        max-width: 16rem;

        > p
        {
            text-align: center;
            margin: 0;
            text-transform: uppercase;
            font-size: .8rem;
            font-family: $altFont;
            font-weight: normal;
            letter-spacing: 1px;
            line-height: 1.25em;
            grid-area: a;
        }
    }

    .footer-awards
    {
        img
        {
            background-color: #fff;
            border-radius: 8px;
            padding-top: 5px;
            max-width: 140px;
        }

        @include media-breakpoint-down(sm)
        {
            text-align: center;
        }
    }

    #footer-nav-contact
    {
        a
        {
            font-size: 1.1rem;
            line-height: 0;
        }

        > .footer-contact-social
        {
            margin-top: 1.2rem;
            display: flex;
            gap: .25em;
        }
    }

    #footer-copyright
    {
        font-weight: 300;
        font-size: 0.875em;
        line-height: 1.1;
        margin-top: $sectionPadding;

        p
        {
            font-weight: 500;

            @include media-breakpoint-down(sm)
            {
                line-height: 1.4;
            }

            @include media-breakpoint-up(md)
            {
                margin-bottom: 0;
            }
        }

        a
        {
            color: $colorGrey;
        }

        .copyright-legal
        {
            font-size: 0.9286em;
        }
    }
}

#onetrust-consent-sdk #onetrust-banner-sdk
{
    box-shadow: 0 0 0.5rem 0 rgba(black,0.5);
}

#onetrust-banner-sdk #onetrust-policy
{
    margin-top: 1.6667em !important;
}

.footer-buttons
{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    position: fixed;
    background-color: $colorAccent;
    bottom: 0;
    height: 5rem;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-family: $altFont;
    box-shadow: $baseShadow;
    z-index: 100;

    > a,
    > div
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1;
        gap: .5rem;
        text-decoration: none;

        &:not(:last-child)::after
        {
            content: "";
            position: absolute;
            border-right: 1px solid #fff;
            height: 3rem;
            right: 0;
            opacity: 0.5;
        }

        > img,
        > button > img
        {
            height: 1.67rem;
        }

        > button
        {
            padding: 0;
            margin: 0;
            border: 0;
            outline: 0;
            background-color: transparent;
            appearance: none;
            font-family: $altFont;
            color: $colorInverse;
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 1;
            gap: .5rem;
            text-align: center;
            align-items: center;
            font-weight: bold;
        }
    }

    > a
    {
        position: relative;
    }

    @include media-breakpoint-up(md)
    {
        display: none;
    }

    #footer-mob-nav
    {
        position: absolute;
        bottom: 100%;
        background-color: $colorAccent;
        box-shadow: $baseShadow;
        width: 100%;
        left: 0px;
        padding: 1rem 1.5rem;
        z-index: 1000;
        max-height: calc(100vh - 5rem);
        overflow: auto;
        text-align: left;

        ul.dropdown-menu
        {
            position: static !important;
            transform: none !important;
            background: transparent;
            text-align: left;
            margin-bottom: 1rem;
            padding: 1rem;
        }

        .dropdown
        {
            > a:after
            {
                content: "\f107";
                @include line-awesome();
                margin-left: .5em;
            }

            &.show > a:after
            {
                content: "\f106";
            }
        }

        .dropdown-menu .dropdown
        {
            > a:after
            {
                content: "\f105";
            }

            &.show > a:after
            {
                content: "\f104";
            }
        }
    }
}

@include media-breakpoint-down(sm)
{
    body
    {
        padding-bottom: 3rem;
    }

    .grecaptcha-badge
    {
        bottom: 6rem !important;
    }
}
