.vacancy-listing
{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
    grid-gap: 2rem;
    margin-bottom: 2rem;

    @include media-breakpoint-down(sm)
    {
        margin-top: 2rem;
        grid-template-columns: 1fr;
    }
}

.card.card-vacancy
{
    padding: 2rem;
    box-shadow: $baseShadow;
    text-decoration: none;
    color: $colorBaseMid;

    > h4
    {
        color: $colorAccent;
        font-family: $altFont;
        margin-bottom: .3333rem;
    }

    > .vacancy-location
    {
        font-family: $altFont;
        text-transform: uppercase;
        font-size: .8rem;
        line-height: 1.25;
        font-weight: bold;
        margin-bottom: .5rem;
    }

    > p
    {
        line-height: 1.667;
    }

    .list-inline-item:not(:last-child)
    {
        margin-right: .25rem;
    }

    span.news-read-more
    {
        margin-right: auto;
    }
}

.popover .list-unstyled
{
    line-height: 1.33;
    text-transform: uppercase;
    font-family: $altFont;
    font-size: .75rem;

    li
    {
        margin-bottom: .25rem;
    }
}
