.dropdown-autocomplete
{
    display: block;
    width: 100%;

    > .dropdown-item
    {
        color: $colorBlue;

        &:hover, &:active, &:focus
        {
            background: $colorAccent;
            color: $colorInverse;
        }
    }
}
