.list-offices
{
    margin-bottom: 0;
    font-family: $altFont;
    font-weight: 500;
    columns: 13em 4;
    @include uppercaseText();

    li
    {
        font-size: .8rem;
    }

    a
    {
        text-decoration: none;

        &:hover,
        &:focus,
        &:active
        {
            color: $colorBaseLight;
        }
    }

    i
    {
        margin-left: .5em;
        color: $primary;
    }
}

.opening-times
{
    table
    {
        th, td
        {
            border-top-width: 0;
            line-height: 1.5;
            padding: 0;
        }
    }

    p
    {
        font-size: .8rem;
        line-height: 1.667;
    }
}