@import "inc/variables";
@import "inc/bootstrap/defer";

$la-css-prefix: la;
@import "~line-awesome/dist/line-awesome/scss/line-awesome";
@import "~font-awesome/scss/font-awesome";
@import "~jssocials/styles/jssocials";
@import "~jssocials/styles/jssocials-theme-flat";

html, body
{
    background-color: $colorLightGrey;
}

.jssocials-share-twitter
{
    .jssocials-share-link
    {
        background-color: #000 !important;
    }

    img.jssocials-share-logo
    {
        height: 1.5em;
        margin: -.25em;
    }
}

@import "inc/colorbox";
@import "inc/la-fix";

@import "parts/tabs";
@import "parts/cards-profile";
@import "parts/cards-vacancy";

@import "parts/list-alphabet";
@import "parts/autocomplete";

@import "parts/pagination";
@import "parts/accreditations";
@import "parts/footer";
@import "parts/fixed-button";
@import "parts/offices";
@import "parts/news";
@import "parts/promos";
@import "parts/review";
@import "parts/js-socials";
@import "parts/sitemap";
@import "parts/loading";
@import "parts/christmas-banner-images";
