.pagination
{
    display: inline-flex;

    .page-prev,
    .page-next
    {
        background-color: $colorLightBlue;
        color: white;
        padding: .25rem 1rem;
        font-size: 1.25rem;
        text-decoration: none;
        cursor: pointer;

        &.disabled
        {
            background-color: lighten($colorLightBlue, 30%);
            cursor: not-allowed;
        }
    }

    .page-current
    {
        background-color: white;
        color: $colorAccent;
        @include uppercaseText();
        padding: .25rem 1rem;
        font-size: .75rem;
        font-weight: bold;
        display: flex;
        align-items: center;
    }
}
