.news-item
{
    margin-bottom: 2.5rem;

    + .news-item
    {
        margin-top: 6rem;

        @include media-breakpoint-down(md)
        {
            margin-top: 0;
        }
    }

    h3
    {
        font-weight: bold;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    img.embed-responsive-item
    {
        object-fit: cover;
    }

    :link
    {
        text-decoration: none !important;
    }

    .list-inline-item:not(:last-child)
    {
        margin-right: .25rem;
    }

    @include media-breakpoint-down(md)
    {
        .promo-block-item
        {
            height: auto;
        }
    }
}

header.news-item
{
    margin-bottom: 1rem;
}

a.news-badge,
span.news-badge
{
    @include uppercaseText();
    display: inline-block;
    font-size: .75rem;
    font-weight: bold;
    line-height: 1;
    color: $colorInverse;
    padding: .333rem .75rem .4rem;
    text-align: center;

    &.news-badge-date
    {
        background-color: $colorAccent;
    }

    &.news-badge-category
    {
        background-color: $colorAlternate;
    }

    &.news-badge-staff
    {
        position: relative;
        color: $colorAlternate;
        padding-left: 2.5rem;

        > img
        {
            position: absolute;
            width: 2rem;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }
}

.news-content
{
    margin: 0 0 1rem;

    img
    {
        max-width: 100%;
    }
}

a.news-read-more,
span.news-read-more
{
    @include arrowButton();
    @include uppercaseText();
    text-decoration: none;
}

a.news-view-more
{
    margin-top: 1rem;
    float: right;

    @include media-breakpoint-down(sm)
    {
        float: none;
        width: 100%;
    }
}

.news-photo
{
    @include media-breakpoint-up(md)
    {
        float: right;
    }

    .embed-responsive-item
    {
        max-width: 100%;
    }
}


.news-staff-block
{
    > h3
    {
        margin-bottom: 0.333rem;
        line-height: 0.8;
        font-size: 1.5rem;
        font-weight: 100;

        > strong
        {
            font-size: 2rem;
            font-weight: 700;
            display: block;
        }

        @include media-breakpoint-down(lg)
        {
            font-size: 1.333rem;

            > strong
            {
                font-size: 1.9rem;
            }
        }

        @include media-breakpoint-down(md)
        {
            > strong
            {
                font-size: 1.75rem;
            }
        }
    }

    .card-staff-news
    {
        border-top: 8px solid $colorAlternate;

        > .card-body
        {
            padding: 1.5rem;
        }

        .staff-news-title
        {
            font-family: $altFont;
            letter-spacing: 1px;
            line-height: 1.25;
            display: block;
            font-weight: 600;
            color: $colorAlternate;
            text-decoration: none;

            &:hover
            {
                color: $colorLightBlue;
            }

            @include uppercaseText();
        }

        ul.list-inline
        {
            margin-bottom: 0;
        }
    }
}
