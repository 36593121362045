a#page-sticky-cta,
button.safety-exit
{
    display: block;
    z-index: 100;
    background-color: $colorRed;
    color: $colorInverse;
    text-decoration: none;
    font-weight: 700;
    box-shadow: $baseShadow;
    text-align: center;
    border: 0;
    left: 0;
    right: 0;
}

a#page-sticky-cta
{
    display: none;
    position: fixed;
    bottom: 0;
    padding: .75em 1.5em;

    @include media-breakpoint-up(md)
    {
        display: block;
        top: 50vh;
        bottom: auto;
        right: auto;
        transform-origin: top left;
        transform: rotate(-90deg) translateX(-100%);
    }
}


button.safety-exit
{
    position: fixed;
    padding: .5em 1.5em;
    margin: 0 auto 1em;
    top: 0;
    left: 0;
    right: 0;
    height: 3rem;

    @include media-breakpoint-up(md)
    {
        position: fixed;
        top: auto;
        bottom: 0;
        right: auto;
        left: 1rem;
        margin: 0;
    }
}
